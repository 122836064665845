// アンケートフォーム

<template lang="pug">
article.student-survey

  .wrapper(v-if='data')

    .back
      a(@click='goBack') &lt; 一覧に戻る

    h1 {{ title }}

    template(v-if='isActive')
      survey-form(
        :items='formItems',
        v-model='answer',
        :agreement.sync='agreement',
        ref='form'
      )
      .buttons
        el-button(
          :disabled='!agreement',
          @click='showDialog',
          type='primary'
        ) 回答内容を確認

    p(v-else) このアンケートは終了しています。

  confirm-answer-dialog(
    :visible.sync='dialogVisible',
    :answer='answer',
    :items='formItems',
    :token='token',
    :survey-id='surveyId',
    @ok='completed'
  )
</template>

<script>
import get from 'lodash.get'

import SurveyForm from '@/components/survey-form'

import ConfirmAnswerDialog from '@/dialogs/confirm-answer-dialog'

import getTokenByAccessKey from '@/api/get-token-by-access-key'
import getSurvey from '@/api/get-survey'

export default {
  name: 'StudentSurvey',

  components: {
    SurveyForm,
    ConfirmAnswerDialog,
  },

  data() {
    return {
      token: null,
      data: null,
      answer: null,
      agreement: false,
      dialogVisible: false,
    }
  },

  computed: {
    surveyId() {
      return parseInt(this.$route.params.surveyId, 10)
    },
    accessKey() {
      return this.$route.params.key
    },
    formItems() {
      return get(this.data, 'form', null)
    },
    title() {
      return get(this.data, 'title', null)
    },
    isActive() {
      return get(this.data, 'isActive', false)
    },
  },

  async created() {
    const response = await getTokenByAccessKey(this.accessKey)
    if (!response.ok) return
    this.token = response.payload.token
    await this.init()
  },

  methods: {
    async init() {
      if (!this.token) return
      const response = await getSurvey(this.surveyId, this.token)
      if (!response.ok) return
      this.data = response.payload
    },

    async showDialog() {
      try {
        await this.$refs.form.validate()
        this.dialogVisible = true
      } catch (e) {
        this.$alert('入力に誤りがあります。', 'エラー', {
          type: 'error',
        })
      }
    },

    // 前のページに戻る
    goBack() {
      this.$router.push({
        name: 'StudentTop',
        query: {
          initialTab: 'survey',
        },
      })
    },

    // 回答完了
    completed() {
      this.$alert('アンケートを送信しました。', '送信完了', {
        type: 'success',
      })
      this.goBack()
    },
  },
}
</script>

<style lang="sass" scoped>
.student-survey

  .wrapper
    .back
      a
        cursor: pointer

    h1
      font-size: 1.2rem
      border-bottom: 3px solid $color-1
      line-height: 1.2
      padding: 0.5rem 0.3rem
      margin: 1rem 0

    .buttons
      margin-top: 2rem
      text-align: center
    max-width: 800px
    margin: 0 auto
</style>
